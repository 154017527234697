<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id" :disabled="isCreate && item.id == 2 ? true : false">{{
            item.tab
          }}
        </v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content " v-model="tabIndex">
        <v-tab-item active>
          <t-ask-content class="tab-detail"
                         :grades="grades"
                         :subjects="subjects"
                         :ask="detailAsk"
                         :isNew="isCreate">

          </t-ask-content>
        </v-tab-item>
        <v-tab-item>
          <t-ask-seo class="tab-detail" :ask="detailAsk"></t-ask-seo>
        </v-tab-item>
        <!--        <v-tab-item>-->
        <!--          <hnr-edit-table class="tab-detail" :content="categories"-->
        <!--                          @addRow="addRow"-->
        <!--                          @updateData="updateItemTable"-->
        <!--                          @deleteItem="deleteItem"></hnr-edit-table>-->
        <!--        </v-tab-item>-->
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(saveLesson)">Lưu bài viết</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center">
        <v-btn color="primary" @click="handleSubmit(updateLesson)">Cập nhật bài viết</v-btn>
      </v-row>

      <v-dialog
          v-model="dialog"
          max-width="600px"
          min-height="600px"
      >
        <!--        <v-card>-->
        <!--          <v-card-title class="text-center">-->
        <!--            <span class="headline">Thêm chủ đề cho bài viết</span>-->
        <!--          </v-card-title>-->
        <!--          <v-card-text>-->
        <!--            <v-container>-->
        <!--              <v-row class="align-start justify-center ma-0">-->
        <!--                <v-col cols="8">-->
        <!--                  <hnr-input :text="search" v-model="search"></hnr-input>-->
        <!--                </v-col>-->
        <!--                <v-col cols="4">-->
        <!--                  <v-btn color="primary" @click="searchCategoriesByName">Tìm kiếm</v-btn>-->
        <!--                </v-col>-->
        <!--              </v-row>-->
        <!--              <v-row class="align-start justify-center ma-0">-->
        <!--                <v-data-table-->
        <!--                    v-model="selected"-->
        <!--                    :headers="headers"-->
        <!--                    :items="suggestNewCategories"-->
        <!--                    :single-select="singleSelect"-->
        <!--                    item-key="id"-->
        <!--                    show-select-->
        <!--                    class="elevation-1"-->
        <!--                >-->
        <!--                </v-data-table>-->
        <!--              </v-row>-->
        <!--              <v-row class="align-center justify-center ma-0 pt-5">-->
        <!--                <v-btn color="primary" @click="handleSubmit(saveNewsCategory)">Lưu chủ đề của bài viết</v-btn>-->
        <!--              </v-row>-->
        <!--            </v-container>-->
        <!--          </v-card-text>-->
        <!--        </v-card>-->
      </v-dialog>
    </ValidationObserver>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";

// stores
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {SAVE_ASK, UPDATE_ASK, DETAIL_ASK} from "@/store/ask.module";
import {GET_LIST_SUBJECTS} from "@/store/subject.module";
import {GET_LIST_GRADE} from "@/store/grade.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
// components
import TAskContent from "@/components/ask/TAskContent";
import TAskSeo from "@/components/ask/TAskSeo";
// import HnrInput from '@/components/elements/hnr-input'
// import HnrEditTable from '@/components/elements/hnr-edit-table'

export default {
  data() {
    return {
      detailAsk: {
        id: '',
        title: "",
        slug: "",
        content: "",
        seo_title: "",
        seo_description: "",
        seo_key: ""
      },
      slug: null,
      isCreate: false,
      isRedirect: true,
      tabIndex: 0,
      items: [
        {id: 0, tab: "Thông tin"},
        {id: 1, tab: "Cấu hình SEO"},
      ],
      categories: [],
      dialog: false,

      suggestNewCategories: [],
      // selectedCategories: [],
      value: 0,
      search: null,
      singleSelect: true,
      selected: [],
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        }
      ],

    };
  },
  components: {
    // HnrInput,
    TAskContent,
    TAskSeo,
    // HnrEditTable
  },
  computed: {
    ...mapGetters({
      ask: "detailAsk",
      subjects:"listSubjects",
      grades:"listGrade",
    }),
  },
  watch: {
    ask: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.detailAsk = newVal;
      },
    },
  },
  created() {
    this.slug = this.$route.params.slug;
    this.$store.dispatch(GET_LIST_SUBJECTS)
    this.$store.dispatch(GET_LIST_GRADE)
    this.resetAsk()
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: this.slug}]);
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(DETAIL_ASK, {slug: this.slug}).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm bài viết"}]);
    }
  },

  methods: {
    resetAsk(){
      this.detailAsk = {}
    },
    regexImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src\s*=\s*"/g,
                function () {
                  return 'src="' + process.env.VUE_APP_BASE_URL + "/";
                }
            );
            return match;
          }
      );
      return content;
    },

    saveLesson() {
      this.isRedirect = false;
      if(this.detailAsk.subject){
        this.detailAsk.subject_id=this.detailAsk.subject.id;
        this.detailAsk.subject = null;
      }
      if(this.detailAsk.grade){
        this.detailAsk.grade_id=this.detailAsk.grade.id;
        this.detailAsk.grade = null;
      }
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(SAVE_ASK, this.detailAsk).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Thêm bài viết thành công!", {root: true});
          this.$router.push('/ask');
        } else {
          this.$store.dispatch(ERROR, data.message, {root: true});
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateLesson() {
      this.isRedirect = false;
      this.$store.dispatch(SET_LOADING, true);
      if(this.detailAsk.subject){
        this.detailAsk.subject_id=this.detailAsk.subject.id;
        this.detailAsk.subject = null;
      }
      if(this.detailAsk.grade){
        this.detailAsk.grade_id=this.detailAsk.grade.id;
        this.detailAsk.grade = null;
      }
      this.$store.dispatch(UPDATE_ASK, this.detailAsk).then(data => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, "Cập nhật bài viết thành công!", {root: true});
          this.$router.push('/ask');
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Cập nhật bài viết thất bại!", {
              root: true,
            });
          }
        }
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(ERROR, "Thêm bài viết thất bại!", {root: true});
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    updateImageUrl(content) {
      if (!content) {
        return null;
      }
      content = content.replace(
          /<img([\w\W]+?)>/gi,
          function (match) {
            match = match.replace(
                /src="([^"]+)"/g,
                function (match_child) {
                  let base_url = process.env.VUE_APP_BASE_URL
                  var regex = new RegExp(base_url + "/(.*)", "");
                  let relative_path = "";
                  let relative_path_arr = match_child.match(regex)
                  if (relative_path_arr.length > 0) {
                    relative_path = relative_path_arr[1];
                    return 'src="' + relative_path;
                  }
                }
            );
            return match;
          }
      );
      return content;
    },

    addRow() {
      this.dialog = true;
    },
    updateItemTable(payload) {
      var index = this.categories.findIndex(item => item.id == payload.id);
      this.categories[index] = payload;
    },
    // deleteItem(item) {
    //   if (confirm("Are you sure you want to delete this item?")) {
    //     let payload = { news_id: this.detailAsk.id, news_category_id: item.id };
    //
    //     this.$store.dispatch(SET_LOADING, true);
    //     this.$store.dispatch(DELETE_CATEGORY_LESSON, payload).then(data => {
    //       if (data.status) {
    //         this.$store.dispatch(SUCCESS, "Xóa chủ đề thành công!", { root: true });
    //       }
    //       else {
    //         this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
    //       }
    //       this.$store.dispatch(SET_LOADING, false);
    //     }).catch(() => {
    //       this.$store.dispatch(ERROR, "Xóa chủ đề thất bại!", { root: true });
    //       this.$store.dispatch(SET_LOADING, false);
    //     });
    //   }
    // },

  },
  beforeRouteLeave(to, from, next) {
    if (this.isRedirect) {
      if (confirm("Bạn chưa lưu bài viết.Bạn vẫn muốn rời đi chứ?")) {
        next()
      } else {
        next(false)
      }
    } else {
      next();
    }
  },
};
</script>


<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
