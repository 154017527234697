<template>
  <div>
    <div class="icons-toggle">
      <button @click="toggleButton('special')" ><img src="assets/images/icons/pi.svg" alt=""/></button
      ><button @click="toggleButton('normal')" ><img src="assets/images/icons/om.svg" alt=""/></button>
      <!--                        <a href="#"><img src="~assets/images/icons/ghim.svg" alt=""/></a>-->
    </div>
    <div v-if="isShowFormula">
      <div class="formula-box">
        <div class="formula-preview card-border">
          <p class="formula-preview-title">Xem trước</p>
          <p ref="mathJaxEl">{{ formula }}</p>
        </div>
      </div>
        <div class="virtual-keyboard-container" v-if="buttonType === 'special'">
          <math-button class="math-button-wrapper"
                       v-for="(item,index) in buttons" :key="index"
                       :icon_src="item.icon_src"
                       :icon_type="item.type"
                       @click="getButtonValue(item.value)"
          />
        </div>
        <div class="virtual-keyboard-container" v-if="buttonType === 'normal'">
          <normal-math-button class="math-button-wrapper"
                              v-for="(item,index) in buttons_normal" :key="index"
                              :character="item.character"
                              @click="getNormalButtonValue(item.character)"
          />
        </div>
      <textarea placeholder="Chỉnh sửa công thức..." class="formula-textarea card-border" v-model="formula" cols="30"
                rows="3"></textarea>
      <v-btn @click="addFormula">Thêm công thức</v-btn>
    </div>
  </div>
</template>

<script>
import MathButton from "@/components/math/MathButton";
import NormalMathButton from "@/components/math/NormalMathButton";

export default {
  props: {
    formula_prop: {
      type: String
    },
    safe: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  components: {
    MathButton,
    NormalMathButton
  },
  data() {
    return {
      formula: "",
      buttonType: "",
      isShowFormula: false,
      buttons: [
        {
          icon_src: "assets/icons/math/is-less-than-or-equal-to-mathematical-symbol.svg",
          value: "$\\leq$"
        }, {
          icon_src: "assets/icons/math/is-equal-to-or-greater-than-symbol.svg",
          value: "$\\geq$ "
        }, {
          icon_src: "assets/icons/math/square-root-mathematical-symbol.svg",
          value: "$\\sqrt[n]{x}$ "
        }, {
          icon_src: "assets/icons/math/is-not-equal-to-mathematical-symbol.svg",
          value: "$\\neq$"
        }, {
          icon_src: "assets/icons/math/pi-mathematical-constant-symbol.svg",
          value: "$\\pi$"
        }, {
          icon_src: "assets/icons/math/integral-mathematical-sign.svg",
          value: "$\\int\\limits^a_b {x} \\, dx$ ",
          type: "medium"
        }, {
          icon_src: "assets/icons/math/limit.svg",
          value: " $\\lim_{n \\to \\infty} a_n$",
          type: 'big'
        }, {
          icon_src: "assets/icons/math/1.svg",
          value: "$x^{2}$ "
        }, {
          icon_src: "assets/icons/math/alpha.svg",
          value: "$\\alpha$ "
        }, {
          icon_src: "assets/icons/math/fraction.svg",
          value: "$\\frac{x}{y}$ "
        },
        {
          icon_src: "assets/icons/math/somol.svg",
          value: "$x_{123}$ "
        }, {
          icon_src: "assets/icons/math/22.svg",
          value: "$\\left \\{ {{y=2} \\atop {x=2}} \\right.$ "
        }, {
          icon_src: "assets/icons/math/014-matrix.svg",
          value: "$\\left[\\begin{array}{ccc}1&2&3\\\\4&5&6\\\\7&8&9\\end{array}\\right]$ ",
          type: "medium"
        },
      ],
      buttons_normal: [
        {
          'character': '²'
        }, {
          'character': '³'
        }, {
          'character': '√'
        }, {
          'character': '∛'
        }, {
          'character': '·'
        }, {
          'character': '×'
        }, {
          'character': '÷'
        }, {
          'character': '±'
        }, {
          'character': '≈'
        }, {
          'character': '≤'
        }, {
          'character': '≥'
        }, {
          'character': '∈'
        }, {
          'character': '∉'
        }, {
          'character': '∧'
        }, {
          'character': '∨'
        }, {
          'character': '∞'
        }, {
          'character': 'Δ'
        }, {
          'character': 'π'
        }, {
          'character': 'Ф'
        }, {
          'character': 'ω'
        }, {
          'character': '↑'
        }, {
          'character': '↓'
        }, {
          'character': '∵'
        }, {
          'character': '∴'
        }, {
          'character': '↔'
        }, {
          'character': '→'
        }, {
          'character': '←'
        }, {
          'character': '⇵'
        }, {
          'character': '⇅'
        }, {
          'character': '⇄'
        }, {
          'character': '⇆'
        }, {
          'character': '∫'
        }, {
          'character': '∑'
        }, {
          'character': '⊂'
        }, {
          'character': '⊃'
        }, {
          'character': '⊆'
        }, {
          'character': '⊇'
        }, {
          'character': '⊅'
        }, {
          'character': '∀'
        }, {
          'character': '∠'
        }, {
          'character': '∡'
        }, {
          'character': '⊥'
        }, {
          'character': '∪'
        }, {
          'character': '∩'
        }, {
          'character': '∅'
        }, {
          'character': '¬'
        }, {
          'character': '⊕'
        }, {
          'character': '║'
        }, {
          'character': '∦'
        }, {
          'character': '∝'
        }, {
          'character': '㏒'
        }, {
          'character': '㏑'
        },
      ]
    }
  },
  watch: {
    formula_prop: {
      immediate: true,
      handler(newVal) {
        if (!this.formula) {
          this.formula = "";
        } else {
          this.formula = newVal
        }
      }
    },
    formula() {
      this.renderMathJax()
    }
  },
  async mounted() {
    await this.addScript("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-AMS_HTML");
    await this.renderMathJax()
  },
  methods: {
    addScript(url) {
      var script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      document.head.appendChild(script);
    },
    addFormula() {
      this.isShowFormula = false;
      this.$emit('addFormula', this.formula)
    },
    toggleButton(type) {
      if (this.buttonType === type) {
        this.isShowFormula = !this.isShowFormula
      } else {
        this.isShowFormula = true;
        this.buttonType = type;
      }
    },
    getButtonValue(value) {
      this.formula += value;
    },
    getNormalButtonValue(value) {
      this.formula += value;
    },
    renderContent() {
      if (!this.$refs.mathJaxEl) {
        return null;
      }
      if (this.safe) {
        this.$refs.mathJaxEl.textContent = this.formula
      } else {
        this.$refs.mathJaxEl.innerHTML = this.formula
      }
    },
    renderMathJax() {
      this.renderContent()
      console.log("render content")
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['(', ')']],
            displayMath: [['$$', '$$'], ['[', ']']],
            processEscapes: true,
            processEnvironments: true
          },
          // Center justify equations in code and markdown cells. Elsewhere
          // we use CSS to left justify single line equations in code cells.
          displayAlign: 'center',
          'HTML-CSS': {
            styles: {'.MathJax_Display': {margin: 0}},
            linebreaks: {automatic: true}
          },
          ...this.options
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
          this.$refs.mathJaxEl
        ])
      }
    }
  },
}
</script>

<style lang="scss">
.math-button-wrapper {
  //display: inline-block;
  margin: 5px 5px 5px 0;
}

.formula-textarea {
  padding: 10px;
  font-size: 18px;
}

.formula-preview {
  padding: 10px;
  font-size: 18px;
  min-height: 100px;
}

.formula-box {
  color: black;
}

.formula-preview:empty:before {
  content: attr(data-placeholder);
  color: gray
}

.formula-preview-title {
  text-decoration: underline;
  font-size: 16px;
}

.virtual-keyboard-container {
  display: flex;
  flex-wrap: wrap;
}
textarea {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  resize: none;
  width: 100%;
}
.card-border {
  border : .5px solid #a6a6a6;
}
.icons-toggle{
  a + a {
    margin-left: 10px;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
