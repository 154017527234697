<template>
  <div>
    <editor
        ref="editor"
        api-key="owx2hc4zogvrturc6xutvdy3m56ps8d0jns093freif8gjia"
        v-model="textEditor"
        :init="init"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {SAVE_IMAGE_TINYMCE} from "../store/image.module";

export default {
  name: 'app',
  components: {
    'editor': Editor,
  },
  props: {
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      init: {
        selector: "textarea",
        placeholder: "Nhập nội dung",
        toolbar: "undo redo | fullscreen preview |fontselect | fontsizeselect | formatselect |" +
            " bold italic underline strikethrough | forecolor backcolor removeformat| align numlist bullist | lineheight outdent indent| link image media| table  charmap emoticons | code  | pagebreak anchor codesample ",
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template ' +
            'codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap  emoticons',
        images_upload_handler: this.uploadFile,
        statusbar: false,
        menubar: false,
        min_height: 500,
        resize: 'both',
        toolbar_mode: 'wrap',
        setup: (editor) => {
          editor.on('paste', (event) => this.handlePaste(event));
        },
      },
    }
  },
  computed: {
    textEditor: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    async handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf("image") === 0) {
          event.preventDefault();
          let file = item.getAsFile();
          let imageFile = new FormData();
          imageFile.append("upload", file);
          await this.$store.dispatch(SAVE_IMAGE_TINYMCE, imageFile).then((data) => {
            const editor = this.$refs.editor.editor;
            editor.insertContent(`<img src="${data.url}" alt="Image">`);
          })
        }
        break;
      }
      event.preventDefault();
      const clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
      const plainText = clipboardData.getData('text/plain');
      const editor = this.$refs.editor.editor;
      // editor.insertContent(plainText);
      const formattedText = plainText
        // .replace(/\n/g, "<br>")
        .replace(/(?:\r\n|\r|\n)/g, "<br>")
        // .replace(/(\.\s|\.\n|\,)/g, "$1<br>");
      editor.insertContent(formattedText);
    },
    async uploadFile(blobInfo, success, failure) {
      let imageFile = new FormData();
      imageFile.append("upload", blobInfo.blob());
      try {
        await this.$store.dispatch(SAVE_IMAGE_TINYMCE, imageFile).then((data) => {
          success(data.url);
        });
      } catch (e) {
        failure(e);
      }
    },
  }
}
</script>
<style lang="scss" scoped>


:deep .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: rgba(34, 47, 62, 0.7);
  content: attr(data-mce-placeholder);
  position: absolute;
  font-size: 14px !important;
  font-style: italic !important;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: rgba(34, 47, 62, .7);
}
</style>
